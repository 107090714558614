export default {
    data() {
      return {
        form: {
          disposalCompanyId: "",
          isDisposalProxy: true,
          memberName: "",
          memberMail: "",
          tel: "",
          mobilePhone: "",
        },
      };
    },
  };
  