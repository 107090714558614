<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA加入登録（処分）" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">契約情報</h3>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>代理登録</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <div class="c-radio">
                  <input
                    id="auto-update-true"
                    type="radio"
                    name="auto-update"
                    :value="true"
                    v-model="form.isDisposalProxy"
                  />
                  <label class="c-radio__label" for="auto-update-true">
                    <span class="c-radio__box"></span>
                    有り
                  </label>
                </div>
                <div class="c-radio">
                  <input
                    id="auto-update-false"
                    type="radio"
                    name="auto-update"
                    :value="false"
                    v-model="form.isDisposalProxy"
                  />
                  <label class="c-radio__label" for="auto-update-false">
                    <span class="c-radio__box"></span>
                    無し
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">管理者アカウント情報</h3>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>氏名</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                  :value.sync="form.memberName"
                  inputType="text"
                  placeholder="氏名"
                  tooltip="全角12文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="12"
                  :isValid.sync="validate.accountInfo.memberName.isValid"
                  :errMsg.sync="validate.accountInfo.memberName.errMsg"
              />
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label required">
              <label>メールアドレス</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                  :value.sync="form.memberMail"
                  inputType="text"
                  placeholder="メールアドレス"
                  tooltip="メールアドレスを入力してください"
                  :validation="validateMail"
                  :isValid.sync="validate.accountInfo.memberMail.isValid"
                  :errMsg.sync="validate.accountInfo.memberMail.errMsg"
              />
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>電話番号</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                  :value.sync="form.tel"
                  inputType="number"
                  placeholder="電話番号(ハイフンなし)"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.tel.isValid"
                  :errMsg.sync="validate.accountInfo.tel.errMsg"
              />
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>電話番号(携帯)</label>
            </div>
            <div class="c-inputWrap__items">
              <InputText
                  :value.sync="form.mobilePhone"
                  inputType="number"
                  placeholder="電話番号(ハイフンなし)"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.accountInfo.mobilePhone.isValid"
                  :errMsg.sync="validate.accountInfo.mobilePhone.errMsg"
              />
            </div>
          </div>
        </div>
        <div class="l-btns">
          <div class="c-btn secondary small" @click="backHandler">戻る</div>
          <div class="c-btn primary small" @click="formHandler()">登録</div>
        </div>
      </div>

      <!--登録確認のモーダル-->
      <div v-if="isDisplayConfirm" class="c-modal md">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__body">
            CBA加入情報（処分）を登録します。<br />
            よろしいですか？
          </div>
          <ModalFooterButtons :processing="processing">
            <div class="c-btn secondary small" @click="isDisplayConfirm = false">戻る</div>
            <div class="c-btn primary small" @click="confirmHandler()">はい</div>
          </ModalFooterButtons>
        </div>
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import validation from "@/mixin/validation";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import common from "@/mixin/common";
import formValidation from "../mixins/form/formValidation";
import { API_URL } from "@/const";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "disposer-cbacontract-input",
  components: {
    ModalFooterButtons,
    InputText,
  },
  mixins: [validation, formValue, callApi, common, formValidation],
  data() {
    return {
      isDisplayConfirm: false,
      errMsgs: [],
      processing: false,
    };
  },
  methods: {
    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      // アカウント名の全角変換
      this.form.memberName = this.toFullWidthString(this.form.memberName);
      axios
        .post(API_URL.DISPOSER.CBACONTRACT_CREATE, this.form)
        .then(() => {
          this.$router.push("/operate");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayConfirm = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    // モーダルの登録押下
    confirmHandler() {
      this.form.disposalCompanyId = this.$route.params.id;
      this.errMsgs = [];
      this.postForm();
    },
    // フォームの登録押下
    formHandler() {
      if (!this.runValidate()) {
        this.processing = false;
        return;
      }
      this.isDisplayConfirm = true;
    },
    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.go(-1);
    },
  },
};
</script>
