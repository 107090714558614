export default {
    data() {
      return {
        validate: {
          accountInfo: {
            memberName: { isValid: true, errMsg: "" },
            memberMail: { isValid: true, errMsg: "" },
            tel: { isValid: true, errMsg: "" },
            mobilePhone: { isValid: true, errMsg: "" },
          },
        },
  
        isValidForm: {
          accountInfo: true,
        },
      };
    },
    methods: {
      accountInfoValidateCheck() {
        const v = this.validate.accountInfo;
        let isValidForm = true;
  
        if (!v.memberName.isValid) {
          v.memberName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
  
        // 氏名の文字数の制限: 全角12文字以内のチェック
        if (this.form.memberName) {
          if (this.form.memberName.length > 12) {
            v.memberName.errMsg = "全角12文字以内で入力してください。";
            isValidForm = false;
          }
        }
  
        if (!v.memberMail.isValid) {
          v.memberMail.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (this.form.tel.length > 0 && !v.tel.isValid) {
          v.tel.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (this.form.mobilePhone.length > 0 && !v.mobilePhone.isValid) {
          v.mobilePhone.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
  
        return isValidForm;
      },
  
      runValidate() {
        let isValidForm = false;
        this.isValidForm.accountInfo = this.accountInfoValidateCheck();
        for (let k in this.isValidForm) {
          if (this.isValidForm[k]) {
            isValidForm = true;
          } else {
            isValidForm = false;
            break;
          }
        }
  
        return isValidForm;
      },
    },
  };
  